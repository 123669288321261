
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.textblock {
  p {
    max-width: $max-width-blog;
  }

  h2,
  h3,
  h4 {
    margin-top: $space-md;
  }
}

.centered {
  text-align: center;

  p {
    margin-left: auto;
    margin-right: auto;
  }
}

.dropcap:first-letter {
  font-weight: bold;
  font-size: 60px;
  font-size: clamp(60px, 8vw, 100px);
  line-height: 1;
  margin: 0 $space-sm 0 0;
  float: left;
}
