
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.figure {
  position: relative;
  color: $color-accent-fresh;

  &.opacity {
    background-color: $color-background-dark;
  }

  &.dark {
    color: $color-background-dark;
  }

  &.gradient {
    &:before {
      content: '';
      background: linear-gradient(138deg, $color-background-dark, transparent 82%);
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }
}

.figureOuter {
  display: block;
}

.title {
  position: absolute;
  z-index: 2;
  top: 6%;
  left: 6%;
  max-width: 70%;
}

.linkDescription {
  position: absolute;
  z-index: 2;
  bottom: 6%;
  right: 6%;

  &::after {
    content: '›';
    padding-left: $space-xs;
  }
}

.caption {
  position: absolute;
  bottom: 2px;
  right: 2px;
  background: $color-accent-fresh;
  color: $color-background-dark;
  font-size: $font-small;
  padding: $space-xs $space-sm;
}
