
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  margin-bottom: $gutters-vertical;
  background-color: $color-accent-fresh;

  &.simple {
    background-color: transparent;
  }

  &.hasImage {
    height: 45vw;
    min-height: 310px;
    background-color: $color-background-dark;
  }
}

.headerSvg {
  max-height: 200px;
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: calc(15vw);
  z-index: 0;
}

.headerContent {
  width: 100%;
  z-index: 2;

  @include media-breakpoint-up(sm) {
    margin-bottom: calc(2 * $space-xxl);
  }

  .hasImage & {
    color: $color-accent-fresh;
  }

  .simple & {
    margin-bottom: $space-sm;
  }
}

.headerTag {
  text-transform: uppercase;
  padding: $space-xs;
  font-size: $font-x-small;
  background: $color-accent-italian;
  color: $color-accent-fresh;
  font-weight: bold;
  display: inline-block;
  margin-bottom: $space-xs;
}

.headerHeadline {
  margin-top: $space-xs;
}

@include media-breakpoint-up(lg) {
  .header.hasImage {
    height: 520px;
    min-height: 520px;
  }

  .headerHeadline {
    max-width: 680px;
  }
}
